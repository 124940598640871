.notifications-notification {
  
  .ant-btn-link {
    align-items: center;
    display: flex;
    padding-left: 30%;
    color: #FFF;
  }
}

.notifications-drawer {

  h2 {
    font-size: 35px;
    text-align: center;
    font-family: 'Harmattan', sans-serif;
    margin-top: 5px;
    font-weight: bolder;
  }

  .ant-row {
    margin-left: -6px;
    margin-right: -6px;
    margin-top: 15px;
  }

  .notification-counter {
    background: #F7F7F7;
    margin: 0 6px;
    color: #FFF;
    border-radius: 12px;
    margin-bottom: 20px;

    .ant-card-body {
      padding: 12px;
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;
    }

    span {
      width: 100%;
      text-align: center;

      &.counter {
        //margin-bottom: 10px;
        font-size: 20px;
        font-weight: bold;
      }

      &.label {
        font-size: 20px;
        //border-top: solid 1px rgba(255, 255, 255, 0.2);
        //padding-top: 10px;
      }
    }

    &-answered {
      background-color: #A8EB11;
    }

    &-processed {
      background-color: #008793;
    }

    &-error {
      background-color: #de1f1f;
    }

    &-sent {
      background-color: #008793;
    }

    &-opened {
      background-color: #008793;
    }

    &-clicked {
      background-color: #008793;
    }
  }

  .ant-btn {
    margin: 10px auto;
    border-radius: 16px;
    display: block;
    min-width: 220px;
  }
}

.notification-count-badge {
  padding-right: 10px;

  .ant-badge-count {
    box-shadow: none;
    font-size: 8px;
    line-height: 14px;
    height: 13px;

    &.ant-badge-multiple-words {
      padding: 0 4px;
    }
  }
}