.alerts-notification {
  .ant-btn-link {
    color: #FFF;
  }
}

.alerts-drawer {

  .ant-drawer-content {
    background-color: #f5f7fa;
  }

  .ant-drawer-content-wrapper {
    border-radius: 8px;
    overflow: hidden;
    background-color: #f5f7fa;
  }

  .ant-drawer-header {
    background-color: #f5f7fa;
  }

  h2 {
    font-size: 35px;
    text-align: center;
    font-family: 'Harmattan', sans-serif;
    margin-top: 40px;
    font-weight: bolder;
  }

  .ant-row {
    margin-left: -6px;
    margin-right: -6px;
    margin-top: 30px;
  }

  .alert-counter {
    background: #FFFFFF;
    margin: 0 6px;
    border-radius: 12px;
    margin-bottom: 20px;
    overflow: hidden;

    .ant-card-body {
      padding: 0px;
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;
    }

    span {
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      &.counter {
        font-size: 24px;
        font-weight: bold;
        height: 50px;
        color: #444;
      }

      &.label {
        border-top: solid 1px rgba(255,255,255,0.2);
        padding: 12px;
        color: #FFF;
        height: 50px;
      }
    }

    &-not-handled .label {
      background-color: #de1f1f;
    }

    &-in-proccess .label {
      background-color: #FAAD14;
    }

    &-closed .label {
      background-color: #A8EB11;
    }

    &-wows .label {
      background-color: #008793;
    }
  }

  .ant-btn {
    margin: 10px auto;
    display: block;
    min-width: 220px;
  }
}

.alert-count-badge {
  .ant-badge-count {
    box-shadow: none;
    font-size: 8px;
    line-height: 14px;
    height: 13px;

    &.ant-badge-multiple-words {
      padding: 0 4px;
    }
  }
}