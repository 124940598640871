.epsilon-layout {
  background-color: #f5f7fa;
}

.epsilon-content {
  background-color: #f5f7fa;
  min-height: 80vh;
  padding: 1.5%;
}

.epsilon-footer {
  position: relative;
  width: 100%;
  bottom: 0;
  background-color: #f5f7fa;

  text-align: center;
  opacity: 0.5;
}
