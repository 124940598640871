.epsilon-menu-dropdown {
  width: 270px;
  display: flex;
  margin-left: 15px;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;

  
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: 3px;
  padding-left: 15px;
  margin-left: 10px;
  background: #F5F7FA;

  &:hover {
    color: #baaee5;
  }

  .epsilon-menu-dropdown-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 40px;
    width: 100%;
    padding-right: 10px;

    .menu-icon path {
      fill: #7967AB;
    }

    .anticon {
      align-self: flex-end;
    }

    .epsilon-menu-focus-styled {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin-right: 15px;
      margin-left: 15px;
      flex: 1 1;

      .general-title-menu-dropdown {
        line-height: 15px;
        color: #595959;
        font-size: 10px;
        font-weight: 500;
      }

      .general-text-menu-dropdown {
        line-height: 15px;
        color: #7967AB;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  &.fluid {
    background-color: transparent !important;

    .general-title-menu-dropdown {
      color: #F5F7FA !important;
    }
    .general-text-menu-dropdown {
      color: #BAAEE5 !important;
    
    }
  }
}

.ant-dropdown-menu {
  overflow: hidden;
  .ant-dropdown-menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 12px;
    border-bottom: 1px solid #e1e1e1;
    height: 48px;
  }
}
.menu {
  padding: 0;

  .menu-item {
    &:last-child {
      .menu-link {
        border-bottom: none;
        text-decoration: none;
      }
    }
    .menu-link {
      display: flex !important;
      align-items: center !important;
      grid-gap: 5px !important;
      gap: 5px !important;
      width: 95%;
      text-decoration: none;
      font-size: 12px;
      font-weight: 500;

      &:hover {
        color: #7967AB;

        /**.icon-container {
          svg {
            path {
              fill: #7967AB;
            }
          }
        }*/
      }
    }
  }

  .icon-container {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
  }
}


.epsilon-menu-container,
.epsilon-menu-container-open {
  overflow: visible;

  .ant-menu { 
    background-color: #F5F7FA;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
    border: solid 1px #e9e9e9;
    border-top: none;

    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
    padding-bottom: 16px;
  }

  .ant-menu-item {
    margin: 0 !important;
    padding: 0 16px !important;
  }

  .epsilon-menu-dropdown {
    position: absolute;
    top: -60px;
    left: -10px;
    z-index: 9999;
    width: 100%;

    border: solid 1px #e9e9e9;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #F5F7FA;
    border-bottom: none;
    padding-bottom: 20px;
    
    a {
      width: 100%;
    }
  }

  .menu-title {
    display: block;
    padding: 8px 16px;
    background-color: #F5F7FA;
    font-size: 12px;
    border-left: solid 1px #e9e9e9;
    border-right: solid 1px #e9e9e9;
    position: relative;
    z-index: 9999;
    margin-bottom: -1px;
  } 
}

.epsilon-menu-container {
  &::before {
    content: '';
    background-color: #F5F7FA;
    width: 100%;
    height: 50px;
    position: absolute;
    opacity: 1;
    z-index: 1;
    left: 0;
    top: -45px;
  }
}